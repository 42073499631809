/* Scoped styles for SaveActionModal markdown */
.action-modal-markdown {
  color: #4b8052; /* Brand green color */
}

.action-modal-markdown p,
.action-modal-markdown h1,
.action-modal-markdown h2,
.action-modal-markdown h3,
.action-modal-markdown h4,
.action-modal-markdown h5,
.action-modal-markdown h6,
.action-modal-markdown li,
.action-modal-markdown ul,
.action-modal-markdown ol,
.action-modal-markdown a,
.action-modal-markdown td,
.action-modal-markdown th,
.action-modal-markdown table {
  color: #003b1f !important; /* Dark brand green */
}

.action-modal-markdown code {
  color: #003b1f !important;
  background-color: rgba(75, 128, 82, 0.1);
  padding: 2px 4px;
  border-radius: 4px;
}

.action-modal-markdown pre {
  background-color: rgba(75, 128, 82, 0.05);
  border: 1px solid rgba(75, 128, 82, 0.1);
}

/* Table styles */
.action-modal-markdown table {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-collapse: collapse;
  margin-bottom: 1rem;
  background-color: #FDF3E3; /* Brand cream color */
}

.action-modal-markdown th,
.action-modal-markdown td {
  border: 1px solid #4b8052;
  padding: 8px 12px;
  min-width: 120px; /* Ensure cells don't get too narrow */
  color: #4b8052 !important; /* Brand green color */
}

.action-modal-markdown th {
  background-color: #FDF3E3; /* Brand cream color */
  font-weight: 600;
  color: #003b1f !important; /* Dark brand green */
}

.action-modal-markdown tr:nth-child(even) {
  background-color: rgba(253, 243, 227, 0.7); /* Slightly transparent brand cream */
} 