/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Optional: Add any additional custom styles here */
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');


h1 {
  font-family: 'Archivo Black', sans-serif;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 16px;
}
h2 {
  font-family: 'Archivo Black', sans-serif;
  font-size: 24px;
  margin-bottom: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}
h3 {
  font-family: 'Archivo Black', sans-serif;
  font-size: 20px;
  margin-bottom: 16px;
  margin-top: 16px;
}
h4 {
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 32px;
  text-decoration-line: underline;
}
h5 {
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
}

p {
  font-family: 'Quicksand', sans-serif;
  font-size: 15px;
  font-weight: 400;
}
ul {
  font-family: 'Quicksand', sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin-top: 4px;
}

.cl-internal-b3fm6y{
  display: none;
}

.cl-internal-17ydaof {
  /* Your base styles for .cl-internal-17ydaof here */
  max-width: 100%; /* Example base style, adjust as needed */
  margin: 0; /* Example base style, adjust as needed */
}

/* Media query for screens up to 480px wide (30em * 16px) */
@media (max-width: 480px) {
  .cl-internal-17ydaof {
      /* Your responsive styles for .cl-internal-17ydaof here */
      max-width: calc(100vw - 100px); /* Adjusted for demonstration, subtract desired margins */
      margin: 0 auto; /* Center align if needed, adjust margins as desired */
      margin-right: 100px;
  }
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root  {
  color: #d5d4d3 !important;
}

.css-yjsfm1 {
  color: #d5d4d3 !important;
}

.cl-formButtonPrimary{
  background-color: #00BF63;
}

.cl-formButtonPrimary:hover{
  background-color: #004B27;
  color: #C1FF72;
}

.cl-internal-phfxlr{
  width: contain !important;
}

@media (max-width: 480px){
.cl-internal-17ydaof {
    max-width: calc(100vw - 100px);
    margin: 0 auto;
    margin-right: -50px;
}}

.sidebar-transition{
  animation: sideFadeIn 500ms forwards;
  
}

@keyframes sideFadeIn {
  0% {
    transform: translateX(0px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

/* Styles for the sidebar container with thinner scrollbars */
.sidebar-container::-webkit-scrollbar {
  width: 3px; /* Thinner scrollbar */
}

.sidebar-container::-webkit-scrollbar-thumb {
  background-color: #c2ff729e; /* Handle colour */
  border-radius: 8px; /* Rounded corners for the handle */
}

/* Custom scrollbar styles */
.textarea1-scrollbar::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
  
   /* Adjust the height of the scrollbar */
}

.textarea1-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Hide the track */
}

.textarea1-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5); /* Color of the thumb */
  border-radius: 10px; /* Round the corners of the thumb */
  height: 8px;
}

/* For Firefox */
.textarea1-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.5) transparent;
  
}

/* Add to your CSS file */
.phone-input-container .flag-dropdown,
.phone-input-container .flag-dropdown.open,
.phone-input-container .flag-dropdown:hover,
.phone-input-container .selected-flag,
.phone-input-container .selected-flag:hover,
.phone-input-container .selected-flag.open,
.phone-input-container .selected-flag:focus {
  background-color: transparent !important;
}

.phone-input-container .selected-flag:hover,
.phone-input-container .selected-flag:active,
.phone-input-container .selected-flag:focus {
  background-color: transparent !important;
}

.phone-input-container {
  margin: 1rem 0; /* equivalent to my-4 in Tailwind */
}

/* Add this to your existing CSS file */
.scrollbar-hide {
  /* For Firefox */
  scrollbar-width: none;
  /* For Internet Explorer and Edge */
  -ms-overflow-style: none;
}

/* For Webkit browsers like Chrome, Safari */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  background: transparent;
}

